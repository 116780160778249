import type { Entry, EntrySkeletonType } from "contentful";
import type {
  TypeNavDropdown,
  TypeNavGroup,
  TypeNavLink,
} from "~/types/TypeNav";
import type { TypeRoutes } from "~/types/TypeRoutes";
import {
  isTypeArticle,
  isTypeCompany,
  isTypeExternalPage,
  isTypePage,
  isTypeReport,
  isTypeStaff,
  isTypeProject,
  type TypePage,
  type TypePageSkeleton,
  type TypeArticleSkeleton,
  type TypeReportSkeleton,
  type TypeStaffSkeleton,
  type TypeNavigationMainDropdownSkeleton,
  type TypeNavigationMainDropdownGroupSkeleton,
  type TypeNavigationMainDropdownLinkSkeleton,
  isTypeNavigationMainDropdown,
  isTypeNavigationMainDropdownGroup,
  isTypeNavigationMainDropdownLink,
  isTypeShareholderResolution,
  type TypeNavigationMainSkeleton,
  type TypeNavigationColumnsSkeleton,
  type TypeProjectSkeleton,
  type TypeExternalPageSkeleton,
  type TypeShareholderResolutionSkeleton,
} from "~/types/contentful";
import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const buildUrl = (
  content: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">,
) => {
  if (isTypeExternalPage(content)) {
    return content.fields.url;
  } else if (isTypeCompany(content)) {
    return `/resources/companies-assessed/${content.fields.slug}`;
  } else if (isTypeStaff(content)) {
    return content.fields.currentEmployee
      ? `/about/our-team/${content.fields.slug}`
      : null;
  } else if (isTypeProject(content)) {
    const { type, slug } = content.fields;
    if (type === "Engagement") {
      if (slug === "company-focus") {
        return "/engagements";
      } else {
        return `/engagements/${slug}`;
      }
    }
    if (type === "Policy") return `/policy/${slug}`;
    if (type === "Tool") {
      if (slug === "supply-chain-analysis") {
        return `/tools/protein-producer-index/${slug}`;
      } else {
        return `/tools/${slug}`;
      }
    }
    return null;
  } else if (
    isTypeArticle(content)
    || isTypePage(content)
    || isTypeReport(content)
    || isTypeShareholderResolution(content)
  ) {
    const slugs: string[] = [
      content.fields.slug !== "home" ? content.fields.slug ?? "" : "",
    ];

    let parent = content.fields.parent;

    const sysIdsSeen: string[] = [];

    if (parent?.fields) {
      do {
        if (parent.fields.slug !== "home") {
          slugs.push(parent.fields.slug ?? "");
        }

        if (!sysIdsSeen.includes(parent.sys.id)) {
          sysIdsSeen.push(parent.sys.id);
        } else {
          throw new Error(`Infinite loop in page routing detected, sys ID ${parent.sys.id} is a child of itself`);
        }
      } while ((parent = parent.fields.parent));
    }

    return `/${slugs.reverse().join("/")}`;
  }

  throw new Error(`Failed to build URL for ${content.sys.contentType.sys.id}`);
};

export const listRoutes = async () => {
  let items: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
    = [];

  let results;

  do {
    results = await (
      await import("./contentful-client")
    ).default.getEntries<
      | TypeArticleSkeleton
      | TypeExternalPageSkeleton
      | TypePageSkeleton
      | TypeProjectSkeleton
      | TypeReportSkeleton
      | TypeShareholderResolutionSkeleton
      | TypeStaffSkeleton
    >({
      // @ts-expect-error sys.contentType is actually allowed if no content_type property is provided
      "sys.contentType.sys.id[in]":
        "article,externalPage,page,project,report,shareholderResolution,staff",
      "skip": items.length,
      "include": 0,
      "limit": 1000,
    });

    items = items.concat(results.items);
  } while (results.total > items.length);

  // We make a separate request for companies as we need their associated projects (ie. include: 1)
  // This could potentially be combined with the above, but after doing so for some reason certain
  // companies have an empty array for fields.projects, instead of one containing sys.id for linking
  let companyItems: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
    = [];

  do {
    results = await (
      await import("./contentful-client")
    ).default.getEntries({
      content_type: "company",
      skip: companyItems.length,
      include: 1,
      limit: 1000,
    });

    companyItems = companyItems.concat(results.items);
  } while (results.total > companyItems.length);

  items = items.concat(companyItems);

  const routes: TypeRoutes = [];

  const sysIdPageIndex = Object.fromEntries(
    items
      .filter(
        (entry): entry is TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB"> =>
          isTypePage(entry),
      )
      .map((entry) => [entry.sys.id, entry]),
  );

  items.forEach((entry) => {
    if (
      (isTypeArticle(entry) || isTypePage(entry) || isTypeReport(entry))
      && entry.fields.parent
    ) {
      entry.fields.parent = sysIdPageIndex[entry.fields.parent.sys.id];
    }
  });

  items.filter((entry) => !entry.fields.hideFromSitemap).forEach((entry) => {
    if (
      isTypeArticle(entry)
      || isTypeCompany(entry)
      || isTypeExternalPage(entry)
      || isTypePage(entry)
      || isTypeReport(entry)
      || isTypeShareholderResolution(entry)
      || isTypeStaff(entry)
    ) {
      const path = buildUrl(entry);

      if (path) {
        const route = {
          title:
            (isTypeArticle(entry)
              || isTypeExternalPage(entry)
              || isTypePage(entry)
              || isTypeReport(entry)
              || isTypeShareholderResolution(entry)
              ? entry.fields.title
              : entry.fields.name) ?? "",
          sysId: [entry.sys.id],
          contentType: entry.sys.contentType.sys.id,
          lastUpdated: new Date(entry.sys.updatedAt),
        };

        routes.push({ ...route, path });

        if (isTypeCompany(entry)) {
          entry.fields.projects?.forEach((project) => {
            routes.push({ ...route, path: `${path}/${project?.fields.slug ?? ""}`, title: project?.fields.name ?? "" });
          });

          routes.push({ ...route, path: `${path}/downloads`, title: "Downloads" });

          routes.push({ ...route, path: `${path}/print`, title: "Print" });
        }
      }
    }
  });

  items.forEach((entry) => {
    if (isTypeProject(entry)) {
      routes
        .find((route) => route.path === buildUrl(entry))
        ?.sysId.push(entry.sys.id);
    }
  });

  return routes;
};

export const getNav = async (sysId: string) => {
  let items: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
    = [];

  let results;

  do {
    results = await (
      await import("./contentful-client")
    ).default.getEntries<
      | TypeNavigationColumnsSkeleton
      | TypeNavigationMainSkeleton
      | TypeNavigationMainDropdownSkeleton
      | TypeNavigationMainDropdownGroupSkeleton
      | TypeNavigationMainDropdownLinkSkeleton
    >({
      // @ts-expect-error sys.contentType is actually allowed if no content_type property is provided
      "sys.contentType.sys.id[in]":
        "navigationColumns,navigationMain,navigationMainDropdown,navigationMainDropdownGroup,navigationMainDropdownLink",
      "skip": items.length,
      "limit": 100,
    });

    items = items.concat(results.items);
  } while (results.total > items.length);

  const resolver = (
    entry: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">,
  ): TypeNavGroup | TypeNavDropdown | TypeNavLink =>
    isTypePage(entry)
      ? ({
          label: entry.fields.title,
          link: buildUrl(entry),
        } as TypeNavLink)
      : isTypeNavigationMainDropdown(entry)
        ? ({
            name: entry.fields.name,
            primaryLinks: entry.fields.primaryLinks
              ?.flatMap((f) => f ?? [])
              .map(resolver),
            items: entry.fields.items?.flatMap((f) => f ?? []).map(resolver),
          } as TypeNavDropdown)
        : isTypeNavigationMainDropdownGroup(entry)
          ? ({
              title: entry.fields.title,
              links: entry.fields.links.flatMap((f) => f ?? []).map(resolver),
            } as TypeNavGroup)
          : ((isTypeNavigationMainDropdownLink(entry) && {
              label: entry.fields.label,
              link: entry.fields.link ? buildUrl(entry.fields.link) : "",
              icon: entry.fields.icon?.fields.file?.url,
            }) as TypeNavLink);

  return items
    .find(
      (
        item,
      ): item is Entry<
        TypeNavigationMainSkeleton | TypeNavigationColumnsSkeleton,
        "WITHOUT_UNRESOLVABLE_LINKS",
        "en-GB"
      > => item.sys.id === sysId,
    )
    ?.fields.items.flatMap((f) => f ?? [])
    .map(resolver);
};

export const isActiveNav = (
  item: TypeNavGroup | TypeNavDropdown | TypeNavLink,
  route: RouteLocationNormalizedLoaded,
) => {
  if ("link" in item) {
    return route.fullPath.startsWith(item.link);
  } else if ("primaryLinks" in item && item.primaryLinks[0]) {
    return route.fullPath.startsWith(item.primaryLinks[0].link);
  }

  return false;
};
